import React, { useContext, useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import WBCCollapse from "../elements/WBCCollapse";
import { AlertConstants } from "best-common-react";
import InterestListTable from "./InterestListTable";
import InterestListContext from "../../contexts/InterestListContext";
import WBCYearsContext from "../../contexts/WBCYearsContext";
import SelectedTeamContext from "../../contexts/SelectedTeamContext";
import TournamentFormContext from "../../contexts/TournamentFormContext";
import PositionsContext from "../../contexts/PositionsContext";
import InterestListApi from "../../httpClients/InterestListApi";
import { useAlert } from "../hooks/useAlert";
import { useError } from "../hooks/useError";
import { sortPlayers } from "../roster/RosterSortHelper";
import ExportApi from "../../httpClients/ExportApi";
import FileHelper from "../../httpClients/FileHelper";
import TableSortFilterContext from "../../contexts/TableSortFilterContext";
import { WBCPrimaryButton } from "../elements/WBCPrimaryButton";
import GlobalModalContext from "../../contexts/GlobalModalContext";
import AuthContext from "../../contexts/AuthContext";
import { formatDate } from "../../utils/DateUtils";
import { isStatusRequestRequired } from "../../utils/MLBProfileRequestUtil";

const Content = styled.div.attrs(() => ({
  className: "p-4"
}))`
  text-align: left;
  height: 74vh;
  overflow: scroll;
`;
const ButtonHolder = styled.div.attrs(() => ({
  className: "p-4"
}))`
  display: flex;
  flex-direction: row-reverse;
  margin-top: 1rem;
`;
const TextHolder = styled.div.attrs(() => ({
  className: "p-2"
}))`
  display: flex;
  flex-direction: row-reverse;
`;

const InterestText = styled.span`
  font-family: Helvetica;
  font-size: 16px;
  color: ${props => props.theme.wbcBlue};
`;

const MaxText = styled.span`
  font-family: Helvetica;
  font-size: 12px;
  color: ${props => props.theme["dark-grey"]};
`;

const PlayerDocsStyledText = styled.div.attrs(() => ({
  className: "mb-2 mt-3"
}))`
  &&& {
    font-size: 14px;
    color: ${props => props.theme["dark-grey"]};
  }
`;

const InterestListForm = () => {
  // context(s) and state
  const interestListContext = useContext(InterestListContext);
  const globalModalContext = useContext(GlobalModalContext);
  const { selectedYear, selectedWbcYear } = useContext(WBCYearsContext);
  const selectedTeamContext = useContext(SelectedTeamContext);
  const tournamentFormContext = useContext(TournamentFormContext);
  const positionsContext = useContext(PositionsContext);
  const tableSortFilterContext = useContext(TableSortFilterContext);
  const [interestList, setInterestList] = useState([]);
  const authContext = useContext(AuthContext);

  // hooks and variables
  const showAlert = useAlert();
  const showErrors = useError();
  const { posAbbrevToId } = positionsContext.state;
  const { onFormDirty, cleanForm } = tournamentFormContext.state;
  const { team } = selectedTeamContext.state;
  const { teamId: fedTeamId } = selectedTeamContext.state.team;
  const generalRosterSettings = selectedWbcYear && selectedWbcYear.generalRosterSettings;
  const { sortFilters: tableFilters } = tableSortFilterContext.state;
  const [hasPlayersWithoutRequests, setHasPlayersWithoutRequests] = useState(false);
  const {
    positionUpdates,
    removedPlayers,
    displayedPlayers,
    pastWbcFlagUpdates,
    sortFilters,
    rosterSaved
  } = interestListContext.state;
  const { isBOCadmin, isFedAdmin } = authContext.state;
  const canFinalize = isBOCadmin || (isFedAdmin && !team.finalizedStatus?.finalizedInterestDate);
  const finalizedDate = team.finalizedStatus?.finalizedInterestDate;

  useEffect(() => {
    interestList.find(player => player.requestStatus !== null && isStatusRequestRequired(player.requestStatus))
      ? setHasPlayersWithoutRequests(true)
      : setHasPlayersWithoutRequests(false);
  }, [interestList]);

  // dispatches
  const { dispatch: interestDispatch } = interestListContext;
  const { dispatch: tournamentDispatch } = tournamentFormContext;

  // function(s)
  const loadInterestList = useCallback(() => {
    if (fedTeamId && selectedYear && !rosterSaved) {
      InterestListApi.getInterestListPlayers(selectedYear, fedTeamId).then(response => {
        interestDispatch({
          type: "setPlayers",
          players: sortPlayers(sortFilters.col, sortFilters.dir, response)
        });
      });
    } else if (rosterSaved) {
      interestDispatch({
        type: "setRosterSaved",
        rosterSaved: false
      });
    }
  }, [selectedYear, fedTeamId, sortFilters, interestDispatch, rosterSaved]);

  const finalizeRoster = () => {
    if (hasPlayersWithoutRequests) {
      showAlert(`MLB Requests still Required.`, "warning");
    }
    globalModalContext.dispatch({
      type: "openFinalizeRosterModal",
      headerText: "Finalize Interest List",
      content: { team, isInterestList: true }
    });
  };

  const onSave = useCallback(
    (override = false) => {
      const posUpdates = Object.entries(positionUpdates).map(pu => {
        const [profileId, position] = pu;
        const positionId = posAbbrevToId[position];
        return {
          profileId,
          positionId
        };
      });
      const remPlayers = removedPlayers.map(rp => ({ profileId: rp }));
      const flagUpdate = Object.entries(pastWbcFlagUpdates).map(flg => {
        const [profileId, pastWbcFlag] = flg;
        return {
          profileId,
          pastWbcFlag
        };
      });

      InterestListApi.updateInterestListPlayers(
        fedTeamId,
        {
          positionUpdates: posUpdates,
          removedPlayers: remPlayers,
          pastWbcFlagUpdates: flagUpdate
        },
        override
      )
        .then(() => {
          loadInterestList();
          showAlert("Saved");
        })
        .catch(({ response }) => {
          interestDispatch({ type: "resetAfterError" });
          if (response.data.message === "Invalid update: Can't update Position of player on Final Roster") {
            showErrors(response.data.message, () => {
              onSave(true);
            });
          } else {
            showAlert(response.data.message, AlertConstants.TYPES.DANGER);
          }
        });
    },
    [
      showAlert,
      showErrors,
      loadInterestList,
      positionUpdates,
      posAbbrevToId,
      removedPlayers,
      pastWbcFlagUpdates,
      fedTeamId,
      interestDispatch
    ]
  );

  const exportFn = useCallback(() => {
    const { col, dir } = tableFilters;
    ExportApi.exportInterestListList(selectedYear, fedTeamId, col, dir, (url, headers) => ({ url, headers })).then(
      data => {
        const test = new Blob([data.url], { type: data.headers["content-type"] });
        const url = window.URL.createObjectURL(test);
        FileHelper.downloadFile(url, data.headers);
      }
    );
  }, [tableFilters, fedTeamId, selectedYear]);

  // effect(s)
  useEffect(() => {
    if (
      Object.keys(positionUpdates).length > 0 ||
      removedPlayers.length > 0 ||
      Object.keys(pastWbcFlagUpdates).length > 0
    ) {
      onFormDirty();
    } else {
      cleanForm();
    }
    tournamentDispatch({ type: "setOnSave", onSave });
    tournamentDispatch({ type: "setOnCancel", onCancel: loadInterestList });
  }, [
    positionUpdates,
    removedPlayers,
    pastWbcFlagUpdates,
    tournamentDispatch,
    onFormDirty,
    onSave,
    loadInterestList,
    cleanForm
  ]);

  useEffect(loadInterestList, [loadInterestList]);
  useEffect(() => setInterestList(displayedPlayers), [displayedPlayers]);

  // components
  const collapseTitle = (
    <div className="d-flex">
      <div>
        <InterestText>Interest ({displayedPlayers.length})</InterestText>{" "}
        {generalRosterSettings && <MaxText>{generalRosterSettings.interestListMax} player max</MaxText>}
      </div>
      <div className="ml-auto">
        {/* <DownloadIcon
          onClick={event => {
            event.preventDefault();
            event.stopPropagation();
          }}
          /> */}
      </div>
    </div>
  );

  return (
    <Content>
      <WBCCollapse title={collapseTitle} exportable exportFn={exportFn}>
        <PlayerDocsStyledText>Upload documentation and submit players for eligibility</PlayerDocsStyledText>
        <InterestListTable data={interestList} />
      </WBCCollapse>
      {canFinalize && (
        <ButtonHolder>
          <WBCPrimaryButton
            width={160}
            onClick={event => {
              event.stopPropagation();
              finalizeRoster();
            }}
          >
            Finalize Interest List
          </WBCPrimaryButton>
        </ButtonHolder>
      )}
      {!!finalizedDate && (
        <TextHolder> Finalized by Fed on {formatDate(finalizedDate, "MM/DD/YYYY HH:MM")} </TextHolder>
      )}
    </Content>
  );
};

export default InterestListForm;
