import PropTypes from "prop-types";
import React, { forwardRef, useContext } from "react";
import { Droppable } from "react-beautiful-dnd";
import AutoSizer from "react-virtualized-auto-sizer";
import { VariableSizeList } from "react-window";
import styled from "styled-components";
import AuthContext from "../../../contexts/AuthContext";
import FinalizedStatusContext from "../../../contexts/FinalizedStatusContext";
import RosterContext from "../../../contexts/RosterContext";
import TableSortFilterContext from "../../../contexts/TableSortFilterContext";
import { useDraggable } from "../../hooks/useDraggable";
import WBCAccessControl from "../../protected/WBCAccessControl";
import { sortPlayers } from "../RosterSortHelper";
import Player from "./Player";
import PlayerRow from "./PlayerRow";
import PlayerHeader from "./PlayerHeader";
import { useCellResize } from "../../hooks/useCellResize";
import useHasNonCurrentYearAccess from "../../hooks/useHasNonCurrentYearAccess";

const DROPPABLE_ID = "player-table";
const MIN_COLUMN_WIDTH = 80; // min width in pixels matches react data grid min

const columns = [
  {
    key: "",
    width: 60
  },
  {
    key: "lastName",
    name: "Last",
    sortable: true
  },
  {
    key: "firstName",
    name: "First",
    sortable: true
  },
  {
    key: "position",
    name: "Pos",
    sortable: true
  },
  {
    key: "hasPpa",
    name: "PPA",
    sortable: true
  },
  {
    key: "hasOtherDocs",
    name: "All Docs",
    sortable: true,
    width: 100
  },
  {
    key: "uniformNumber",
    name: "Uniform #",
    sortable: true,
    width: 90
  },
  {
    key: "pants",
    name: "Pants",
    sortable: true,
    width: 70
  },
  {
    key: "jersey",
    name: "Jersey",
    sortable: true,
    width: 70
  },
  {
    key: "hat",
    name: "Hat",
    sortable: true,
    width: 70
  },
  {
    key: "org",
    name: "Org",
    sortable: true,
    width: 50
  },
  {
    key: "club",
    name: "Club",
    sortable: true,
    width: 225
  }
];

const noDocsColumns = columns.filter(({ key }) => key !== "hasPpa" && key !== "hasOtherDocs");

const Header = styled.div`
  height: 24px;
  margin-top: 1rem;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: ${props => props.theme["wbcRed"]};
`;

const List = styled(VariableSizeList)`
  border-bottom: 1px solid #ececec;
`;

const Sticky = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 32px;
  z-index: 2;
`;

const InnerElement = forwardRef(({ children, ...rest }, ref) => {
  // context(s)
  const rosterContext = useContext(RosterContext);
  const tableSortFilterContext = useContext(TableSortFilterContext);
  const hasNonCurrentYearAccess = useHasNonCurrentYearAccess();

  // variable(s)
  const { players } = rosterContext.state.provisional;

  // function(s)
  const onSort = (col, dir) => {
    rosterContext.dispatch({
      type: "updatePlayers",
      players: sortPlayers(col, dir, players)
    });
    tableSortFilterContext.dispatch({
      type: "setSortFilters",
      sortFilters: { col, dir }
    });
  };

  return (
    <div id={DROPPABLE_ID} ref={ref} {...rest}>
      <Sticky>
        <PlayerHeader
          columns={hasNonCurrentYearAccess ? columns : noDocsColumns}
          onSort={onSort}
          length={players.length}
          sortColumn="lastName"
          sortDirection="ASC"
        />
      </Sticky>
      {children}
    </div>
  );
});

const PlayerTable = () => {
  // hooks
  const authContext = useContext(AuthContext);
  const rosterContext = useContext(RosterContext);
  const finalizedStatusContext = useContext(FinalizedStatusContext);

  // variable(s)
  const { isBOCadmin } = authContext.state;
  const { players } = rosterContext.state.provisional;
  const { finalized } = finalizedStatusContext.state;

  // pixel width
  const pixelWidth = useCellResize(DROPPABLE_ID, 4, 740, MIN_COLUMN_WIDTH, players.length);

  // effects
  // set up draggability
  useDraggable(DROPPABLE_ID, "provisional");

  return (
    <>
      <Header>A PPA must be attached for each player in order to add player to final roster</Header>
      <WBCAccessControl
        input={isDropDisabled => (
          <Droppable
            mode="virtual"
            type="ATHLETES"
            droppableId={DROPPABLE_ID}
            isDropDisabled={isDropDisabled || (finalized && !isBOCadmin)}
            renderClone={(provided, snapshot, rubric) => (
              <Player
                provided={provided}
                snapshot={snapshot}
                pixelWidth={pixelWidth}
                index={rubric.source.index}
                player={players[rubric.source.index]}
                droppableId={DROPPABLE_ID}
              />
            )}
          >
            {(provided, snapshot) => (
              <div ref={provided.innerRef}>
                <AutoSizer disableHeight>
                  {({ width }) => (
                    <List
                      innerElementType={InnerElement}
                      height={33 + Math.min(players.length * 37, 228)}
                      width={width}
                      itemKey={(index, data) => {
                        const { players } = data;
                        const dataIndex = index - 1;
                        const player = players[dataIndex];
                        return `${DROPPABLE_ID}-${player ? player.profileId : "header"}`;
                      }}
                      itemCount={players.length + (snapshot.isUsingPlaceholder ? 2 : 1)}
                      itemSize={i => (!i ? 32 : 37)}
                      itemData={{ players, pixelWidth, droppableId: DROPPABLE_ID }}
                    >
                      {PlayerRow}
                    </List>
                  )}
                </AutoSizer>
              </div>
            )}
          </Droppable>
        )}
      />
    </>
  );
};

InnerElement.displayName = "InnerElement";
InnerElement.propTypes = {
  children: PropTypes.any
};

export default PlayerTable;
