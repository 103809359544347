import React, { useState, useContext, useCallback } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import WBCCollapse from "../elements/WBCCollapse";
import WBCDataTable from "../data-table/WBCDataTable";
import PlayerLinkFormatter from "../table/PlayerLinkFormatter";
import { QUALIFYING } from "../../constants/TournamentTypeConstants";
import RosterContext from "../../contexts/RosterContext";
import WBCYearsContext from "../../contexts/WBCYearsContext";
import TournamentFormContext from "../../contexts/TournamentFormContext";
import TournamentTypeContext from "../../contexts/TournamentTypeContext";
import GlobalModalContext from "../../contexts/GlobalModalContext";
import WBCAdminAccess from "../protected/WBCAdminAccess";
import { useError } from "../hooks/useError";
import { sortPlayers } from "./RosterSortHelper";
import FinalizedStatusContext from "../../contexts/FinalizedStatusContext";
import AuthContext from "../../contexts/AuthContext";
import TableSortFilterContext from "../../contexts/TableSortFilterContext";
import ExportApi from "../../httpClients/ExportApi";
import FileHelper from "../../httpClients/FileHelper";
import SelectedTeamContext from "../../contexts/SelectedTeamContext";
import ClubFormatter from "../table/ClubFormatter";
import OrgNameFormatter from "../elements/OrgNameFormatter";

const Container = styled.div`
  margin-bottom: 18px;
`;

const AvailableText = styled.p`
  font-family: Helvetica;
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: ${props => props.theme["dark-grey"]};
`;

const AddProvisionalContainer = styled.div`
  text-align: center;
  cursor: ${props => (props.disabled ? "no-drop" : "pointer")};
  color: ${props => props.theme[props.disabled ? "grey" : "wbcBlue"]};
`;

const AvailableTable = styled(WBCDataTable)`
  .react-grid-Viewport {
    height: ${props => `${props.viewportHeight}px`};
  }
`;

const AddProvisionalFormatter = ({ row, dependentValues }) => {
  // hook(s)
  const rosterContext = useContext(RosterContext);
  const tournamentFormContext = useContext(TournamentFormContext);
  const globalModalContext = useContext(GlobalModalContext);
  const authContext = useContext(AuthContext);
  const showErrors = useError();

  // variable(s)
  const { provisionalMax, finalized } = dependentValues;
  const { players } = rosterContext.state.provisional;
  const { onFormDirty } = tournamentFormContext.state;
  const isDisabled = players.some(player => player.profileId === row.profileId);

  // funtion(s)
  const addToProvisionalRoster = () => {
    globalModalContext.dispatch({
      type: "closeModal"
    });
    rosterContext.dispatch({
      type: "addToProvisionalRoster",
      player: row
    });
    onFormDirty();
  };

  const onClick = () => {
    if (isDisabled) return;
    if (players.length >= provisionalMax) {
      showErrors(
        `The player cannot be added to the Provisional Roster because it exceeds the ${provisionalMax} player maximum.`,
        addToProvisionalRoster
      );
    } else {
      addToProvisionalRoster();
    }
  };

  return (
    <AddProvisionalContainer disabled={isDisabled} onClick={onClick}>
      {!finalized || authContext.state.isBOCadmin ? (
        <WBCAdminAccess includesTc={true}>
          <i className="fas fa-user-plus fa-xs" />
        </WBCAdminAccess>
      ) : null}
    </AddProvisionalContainer>
  );
};

const AvailableRoster = () => {
  // context && state
  const rosterContext = useContext(RosterContext);
  const { selectedWbcYear, selectedYear } = useContext(WBCYearsContext);
  const finalizedStatusContext = useContext(FinalizedStatusContext);
  const tableSortFilterContext = useContext(TableSortFilterContext);
  const selectedTeamContext = useContext(SelectedTeamContext);
  const tournamentType = useContext(TournamentTypeContext);
  const [filters, setFilters] = useState({
    dir: "ASC",
    key: "lastName"
  });

  // variables
  const { dispatch: rosterDispatch } = rosterContext;
  const { finalized } = finalizedStatusContext.state;
  const { sortFilters } = tableSortFilterContext.state;
  const { availablePlayers: players, availableIsOpen } = rosterContext.state;
  const { teamId: fedTeamId } = selectedTeamContext.state.team;
  const provisionalMax =
    selectedWbcYear &&
    (tournamentType === QUALIFYING
      ? selectedWbcYear.qualifyingTournamentSettings.rosterNumbers.provisionalMax
      : selectedWbcYear.mainTournamentSettings.rosterNumbers.provisionalMax);

  const columns = [
    {
      key: "addProvisional",
      name: "",
      width: 31,
      formatter: AddProvisionalFormatter,
      getRowMetaData: () => ({
        provisionalMax: parseInt(provisionalMax),
        finalized
      })
    },
    {
      key: "lastName",
      name: "Last",
      width: 120,
      sortable: true,
      formatter: PlayerLinkFormatter
    },
    {
      key: "firstName",
      name: "First",
      width: 120,
      sortable: true,
      formatter: PlayerLinkFormatter
    },
    {
      key: "position",
      name: "Pos",
      width: 75,
      sortable: true
    },
    {
      key: "org",
      name: "Org",
      width: 65,
      sortable: true,
      formatter: OrgNameFormatter
    },
    {
      key: "club",
      name: "Club",
      sortable: true,
      formatter: ClubFormatter
    }
  ];

  // functions
  const sort = (col, dir) => {
    rosterContext.dispatch({
      type: "updateAvailablePlayers",
      players: sortPlayers(col, dir, players)
    });
    setFilters({ key: col, dir });
    tableSortFilterContext.dispatch({
      type: "setSortFilters",
      sortFilters: { col, dir }
    });
  };

  const exportFn = useCallback(() => {
    ExportApi.exportAvailablePlayersListList(
      selectedYear,
      tournamentType,
      fedTeamId,
      sortFilters.col,
      sortFilters.dir,
      (url, headers) => ({ url, headers })
    ).then(data => {
      const test = new Blob([data.url], { type: data.headers["content-type"] });
      const url = window.URL.createObjectURL(test);
      FileHelper.downloadFile(url, data.headers);
    });
  }, [tournamentType, sortFilters, selectedYear, fedTeamId]);

  const clickedAvailableCollapse = isOpen => {
    rosterDispatch({
      type: "setAvailableIsOpen",
      isOpen
    });
  };

  return (
    <Container>
      <WBCCollapse
        title={`Available (${players.length})`}
        startsOpen={availableIsOpen}
        exportable
        exportFn={exportFn}
        openCloseCallback={clickedAvailableCollapse}
      >
        <AvailableText>Add up to {provisionalMax} players to the provisional roster</AvailableText>
        <AvailableTable
          useBCR
          enableRowSelect={null}
          rowScrollTimeout={null}
          columns={columns}
          headerRowHeight={34}
          minHeight={Math.min(35 * players.length + 36, 286)}
          viewportHeight={Math.min(35 * players.length, 250)}
          data={players}
          sortFunc={sort}
          sortColumn={filters.key}
          sortDirection={filters.dir}
        />
      </WBCCollapse>
    </Container>
  );
};

/** Define prop types */
AddProvisionalFormatter.propTypes = {
  row: PropTypes.object,
  dependentValues: PropTypes.object
};

export default AvailableRoster;
