import React, { useEffect, useContext } from "react";
import { MLBPlayersDashboardTab } from "../MLBPlayersDashboardStyles";
import MLBPlayerRequestsHeader from "./MLBPlayerRequestsHeader";
import RequestBody from "./MLBRequestBody";
import WBCYearsContext from "../../../contexts/WBCYearsContext";
import MLBPlayerRequestContext from "../../../contexts/MLBPlayerRequestContext";
import MLBPlayerRequestApi from "../../../httpClients/MLBPlayerRequestApi";
import MLBPlayerSummaryApi from "../../../httpClients/MLBPlayerSummaryApi";

const MLBPlayerRequest = () => {
  const { selectedWbcYear } = useContext(WBCYearsContext);
  const { setPlayerRequests, setPlayerStatuses } = useContext(MLBPlayerRequestContext);
  const ALL_STATUS = { label: "All", value: null };

  useEffect(() => {
    if (selectedWbcYear?.year) {
      const fetchPlayerRequests = async () => {
        const res = await MLBPlayerRequestApi.getMLBPlayerRequestsByYear(selectedWbcYear.year);
        setPlayerRequests(res?.length ? res : []);
      };
      fetchPlayerRequests();
    }

    const fetchPlayerStatuses = async () => {
      const statuses = await MLBPlayerSummaryApi.getStatuses();
      statuses?.length ? setPlayerStatuses([ALL_STATUS, ...statuses]) : setPlayerStatuses([ALL_STATUS]);
    };

    fetchPlayerStatuses();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWbcYear]);

  return (
    <MLBPlayersDashboardTab>
      <MLBPlayerRequestsHeader />
      <RequestBody />
    </MLBPlayersDashboardTab>
  );
};

export default MLBPlayerRequest;
