import { useContext } from "react";
import AuthContext from "../../contexts/AuthContext";
import WBCYearsContext from "../../contexts/WBCYearsContext";

const useHasNonCurrentYearAccess = () => {
  const { isBOC } = useContext(AuthContext).state;
  const { isCurrentYearSelected } = useContext(WBCYearsContext);

  return isBOC || isCurrentYearSelected;
};

export default useHasNonCurrentYearAccess;
