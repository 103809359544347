import PropTypes from "prop-types";
import React, { useCallback, useContext, useRef, useState } from "react";
import styled from "styled-components";

import LabelConstants from "../../../constants/LabelConstants";
import RouteConstants from "../../../constants/RouteConstants";
import AuthContext from "../../../contexts/AuthContext";
import FinalizedStatusContext from "../../../contexts/FinalizedStatusContext";
import GlobalModalContext from "../../../contexts/GlobalModalContext";
import RosterContext from "../../../contexts/RosterContext";
import TournamentFormContext from "../../../contexts/TournamentFormContext";
import openDocumentsModal from "../../../effects/documents/openDocumentsModal";
import { getHasOtherDocs, getHasPpa } from "../../../selectors/DocumentSelectors";
import { removeFromList } from "../../../utils/DragUtils";
import { ContextMenu, MenuItem } from "../../elements/ContextMenu";
import DownloadDocumentsButton from "../../elements/DownloadDocumentsButton";
import UncontrolledInput from "../../elements/UncontrolledInput";
import NonCurrentYearAccess from "../../protected/NonCurrentYearAccess";
import WBCAccessControl from "../../protected/WBCAccessControl";
import WBCAdminAccess from "../../protected/WBCAdminAccess";
import CellText from "../components/CellText";
import LinkText from "../components/LinkText";
import { DraggableRow, DragIcon, ListText, VerticalEllipses } from "./PlayerRowHelper";
import Tooltip from "../../elements/Tooltip";

const Row = styled(DraggableRow)`
  border-bottom: ${props => (props.showBorder ? undefined : "none")};
  height: ${props => (props.isDragging ? undefined : "37px")};
  background-color: ${props => (props.isDragging ? "#f9f3d5" : undefined)};
  background-color: ${props => (props.disabled ? props.theme["almost-white"] : undefined)};
`;

const Cell = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  padding: 8px;
  will-change: width, min-width;
  width: ${props => (props.width ? `${props.width}px` : undefined)};
  min-width: ${props => (props.width ? `${props.width}px` : undefined)};
  background-color: ${props => (props.isOpen ? "#f2f8fe" : undefined)};
  background-color: ${props => (props.isDragging ? "#f9f3d5" : undefined)};
  background-color: ${props => (props.disabled ? props.theme["almost-white"] : undefined)};
  border-bottom: ${props => (props.showBorder ? "1px solid #ececec" : undefined)};
`;

const Ellipsis = styled(VerticalEllipses)`
  margin: 0 6px 0 0;
  display: ${props => (props.isHidden ? "none" : undefined)};
`;

const DragBars = styled(DragIcon)`
  cursor: auto;
`;

const UniformInput = styled(UncontrolledInput).attrs(() => ({
  timeout: 75
}))`
  &&& {
    height: 28px;
    font-size: 14px;
  }
`;

// base scroll width
const NO_PARENT = {
  parentElement: { scrollWidth: 0, clientWidth: 0 }
};

const Player = ({ provided, snapshot, player, droppableId, index, style, pixelWidth }) => {
  // hooks
  const targetRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const authContext = useContext(AuthContext);
  const rosterContext = useContext(RosterContext);
  const tournamentFormContext = useContext(TournamentFormContext);
  const finalizedStatusContext = useContext(FinalizedStatusContext);
  const { dispatch: globalModalDispatch } = useContext(GlobalModalContext);

  // variables from context
  const { isBOC, isBOCadmin } = authContext.state;
  const { dispatch: rosterDispatch } = rosterContext;
  const { players } = rosterContext.state.provisional;
  const { onFormDirty } = tournamentFormContext.state;
  const { finalized } = finalizedStatusContext.state;

  // variables needed for drag functionality to work
  const dragId = `${droppableId}-${player.profileId}`;
  const { isDragging } = snapshot;
  const { innerRef, draggableProps, dragHandleProps } = provided;
  const dragBarRef = useRef(null);

  // variables to update width + styling of row and cells
  const width = isDragging || !pixelWidth ? undefined : pixelWidth;
  const colorProps = { isOpen, isDragging, disabled: player.inFinalRoster };
  const { parentElement } = document.getElementById(droppableId) || NO_PARENT;
  const showBorder = parentElement.scrollWidth > parentElement.clientWidth;
  const cellProps = { ...colorProps, showBorder };
  const mergeStyling = { ...draggableProps.style, ...style };

  // functions
  const toggleMenu = useCallback(value => setIsOpen(value !== undefined ? value : !isOpen), [isOpen, setIsOpen]);
  const removePlayer = () => {
    // remove from players list
    if (player.inFinalRoster) return;
    rosterDispatch({
      type: "updatePlayers",
      players: removeFromList(players, index)
    });
    onFormDirty();
    toggleMenu(false);
  };
  const isDragDisabled = useCallback(() => (!player.hasPpa && !isBOC) || player.inFinalRoster, [isBOC, player]);

  const draggableTooltip = useCallback(() => {
    if (player.inFinalRoster) {
      return "Player already on final roster";
    } else if (!player.hasPpa && !isBOC) {
      return "Player missing PPA";
    }
  }, [isBOC, player]);

  const onDocumentsSaveSuccess = useCallback(
    documentsState => {
      rosterDispatch({
        type: "setPlayerDocFlags",
        profileId: documentsState.profile.profileId,
        hasPpa: getHasPpa(documentsState),
        hasOtherDocs: getHasOtherDocs(documentsState),
        hasNppa: false
      });
    },
    [rosterDispatch]
  );

  const onPlayerDocsClicked = useCallback(() => {
    openDocumentsModal({
      profile: player,
      labelTypeId: LabelConstants.LABEL_TYPE.PLAYER,
      globalModalDispatch,
      onSaveSuccess: onDocumentsSaveSuccess
    });
    toggleMenu(false);
  }, [player, globalModalDispatch, toggleMenu, onDocumentsSaveSuccess]);

  return (
    <Row id={dragId} ref={innerRef} showBorder={!showBorder} {...colorProps} {...draggableProps} style={mergeStyling}>
      <Cell width={60} {...cellProps}>
        {!finalized || isBOCadmin ? (
          <WBCAdminAccess>
            <Ellipsis ref={targetRef} isHidden={isDragging} onClick={() => setIsOpen(!isOpen)} />
            <>
              <DragBars ref={dragBarRef} {...dragHandleProps} disabled={isDragDisabled()} />
              {isDragDisabled() && <Tooltip targetRef={dragBarRef}>{draggableTooltip()}</Tooltip>}
            </>
            <ContextMenu targetRef={targetRef} isOpen={isOpen} toggleMenu={toggleMenu} offsetX={-8} offsetY={7}>
              <MenuItem disabled={player.inFinalRoster} onClick={removePlayer}>
                <i className="fas fa-trash-alt fa-lg" />
                remove player
              </MenuItem>
              <MenuItem onClick={onPlayerDocsClicked}>
                <i className="fas fa-file-upload fa-lg" />
                player docs
              </MenuItem>
            </ContextMenu>
          </WBCAdminAccess>
        ) : null}
      </Cell>
      <Cell width={width} {...cellProps}>
        <LinkText row={player} profileType={RouteConstants.PLAYER}>
          {player.lastName}
        </LinkText>
      </Cell>
      <Cell width={width} {...cellProps}>
        <LinkText row={player} profileType={RouteConstants.PLAYER}>
          {player.firstName}
        </LinkText>
      </Cell>
      {isDragging ? null : (
        <>
          <Cell width={width} {...cellProps}>
            <ListText>{player.position}</ListText>
          </Cell>
          <NonCurrentYearAccess>
            <Cell width={width} {...cellProps}>
              <ListText>
                {player.hasPpa ? (
                  <DownloadDocumentsButton
                    profileId={player.profileId}
                    teamId={player.fedTeamId}
                    docLabelIds={[LabelConstants.LABEL.PPA]}
                    clickableWidth="39px"
                  />
                ) : null}
              </ListText>
            </Cell>
            <Cell width={100} {...cellProps}>
              <ListText>
                {player.hasOtherDocs ? (
                  <DownloadDocumentsButton
                    profileId={player.profileId}
                    teamId={player.fedTeamId}
                    clickableWidth="39px"
                  />
                ) : null}
              </ListText>
            </Cell>
          </NonCurrentYearAccess>
          <Cell width={90} {...cellProps}>
            <ListText>
              <WBCAccessControl
                input={isDisabled => (
                  <UniformInput
                    value={player.uniformNumber || ""}
                    disabled={isDisabled || (!isBOCadmin && finalized)}
                    postChange={value => {
                      rosterDispatch({
                        type: "setUniform",
                        field: "uniformNumber",
                        profileId: player.profileId,
                        value
                      });
                      onFormDirty();
                    }}
                  />
                )}
              />
            </ListText>
          </Cell>
          <Cell width={70} {...cellProps}>
            <ListText>
              <WBCAccessControl
                input={isDisabled => (
                  <UniformInput
                    value={player.pants || ""}
                    disabled={isDisabled || (!isBOCadmin && finalized)}
                    postChange={value => {
                      rosterDispatch({
                        type: "setUniform",
                        field: "pants",
                        profileId: player.profileId,
                        value
                      });
                      onFormDirty();
                    }}
                  />
                )}
              />
            </ListText>
          </Cell>
          <Cell width={70} {...cellProps}>
            <ListText>
              <WBCAccessControl
                input={isDisabled => (
                  <UniformInput
                    value={player.jersey || ""}
                    disabled={isDisabled || (!isBOCadmin && finalized)}
                    postChange={value => {
                      rosterDispatch({
                        type: "setUniform",
                        field: "jersey",
                        profileId: player.profileId,
                        value
                      });
                      onFormDirty();
                    }}
                  />
                )}
              />
            </ListText>
          </Cell>
          <Cell width={75} {...cellProps}>
            <ListText>
              <WBCAccessControl
                input={isDisabled => (
                  <UniformInput
                    value={player.hat || ""}
                    disabled={isDisabled || (!isBOCadmin && finalized)}
                    postChange={value => {
                      rosterDispatch({
                        type: "setUniform",
                        field: "hat",
                        profileId: player.profileId,
                        value
                      });
                      onFormDirty();
                    }}
                  />
                )}
              />
            </ListText>
          </Cell>
          <Cell width={50} {...cellProps}>
            <ListText>
              {player.org ? (player.org && player.org.toLowerCase() === "no" ? "NONE" : player.org) : null}
            </ListText>
          </Cell>
          <Cell width={225} {...cellProps}>
            <CellText>{player.clubId ? player.club : player.clubString}</CellText>
          </Cell>
        </>
      )}
    </Row>
  );
};

Player.propTypes = {
  provided: PropTypes.object,
  snapshot: PropTypes.object,
  player: PropTypes.object,
  style: PropTypes.object,
  droppableId: PropTypes.string,
  index: PropTypes.number,
  pixelWidth: PropTypes.number
};

Player.defaultProps = {
  style: {}
};

export default Player;
