import React, { useRef, useState, useCallback, useContext, useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import AuthContext from "../../../contexts/AuthContext";
import { ContextMenu, MenuButton, MenuIcon, MenuItem } from "../../elements/ContextMenu";
import MLBPlayerRequestApi from "../../../httpClients/MLBPlayerRequestApi";
import MLBPlayerRequestContext from "../../../contexts/MLBPlayerRequestContext";
import GlobalModalContext from "../../../contexts/GlobalModalContext";
import { Icon } from "best-common-react";
import { useAlert } from "../../hooks/useAlert";
import {
  isStatusCancelled,
  isStatusRequestRequired,
  isStatusSubmittedToBOC,
  isStatusSubmittedToClub
} from "../../../utils/MLBProfileRequestUtil";
import ProfileRequestConstants from "../../../constants/ProfileRequestConstants";

const InactiveMenuIcon = styled(Icon)`
  color: ${props => props.theme.grey};
  cursor: pointer;
  &:hover {
    color: ${props => props.theme.grey}; // Keep the same color on hover
  }
`;

const RequestActionMenuFormatter = ({ row }) => {
  const authContext = useContext(AuthContext);
  const { isFedUser, isBOCadmin, isBOCviewer, isTeamCoordinator } = authContext.state;
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [modalAction, setModalAction] = useState(null);
  const { playerRequests, setPlayerRequests } = useContext(MLBPlayerRequestContext);
  const { dispatch } = useContext(GlobalModalContext);
  const showAlert = useAlert();

  const toggleMenu = useCallback(value => setIsOpen(value !== undefined ? value : !isOpen), [isOpen, setIsOpen]);

  const handleSubmitToBOC = () => {
    setModalAction(ProfileRequestConstants.ACTIONS.SUBMIT_TO_BOC);
    setIsOpen(!isOpen);
    setShowConfirmModal(true);
  };

  const handleSubmitToClub = () => {
    setModalAction(ProfileRequestConstants.ACTIONS.SUBMIT_TO_CLUB);
    setIsOpen(!isOpen);
    setShowConfirmModal(true);
  };

  const handleClubDecision = decisionId => {
    setModalAction(decisionId);
    setIsOpen(!isOpen);
    setShowConfirmModal(true);
  };

  const handleCancelRequest = () => {
    setModalAction(ProfileRequestConstants.ACTIONS.CANCEL);
    setIsOpen(!isOpen);
    setShowCancelModal(true);
  };

  const handleResubmitRequest = () => {
    setModalAction(ProfileRequestConstants.ACTIONS.RESUBMIT);
    setIsOpen(!isOpen);
    setShowConfirmModal(true);
  };

  const handleUpdateStatus = (newStatus, showAlertBool = false, alertMessage) => {
    MLBPlayerRequestApi.updatePlayerRequestStatus(row?.profileId, newStatus)
      .then(res => {
        updatePlayerRequests(res, showAlertBool, alertMessage);
      })
      .catch(err => {
        showAlert(`ERROR: Could not update status to ${newStatus}. (${err.message})`, "danger");
      });
  };

  const handleResubmit = (showAlertBool = false, alertMessage) => {
    MLBPlayerRequestApi.resubmitPlayerRequest(row?.profileId)
      .then(res => {
        updatePlayerRequests(res, showAlertBool, alertMessage);
      })
      .catch(err => {
        showAlert(`ERROR: Could not resubmit. (${err.message})`, "danger");
      });
  };

  const updatePlayerRequests = (res, showAlertBool = false, alertMessage) => {
    const updatedPlayerRequests = playerRequests.map(request => {
      if (request.profileId === row.profileId) {
        return { ...request, requestStatus: res.requestStatus };
      }
      return request;
    });
    if (showAlertBool) {
      showAlert(alertMessage);
    }
    setPlayerRequests(updatedPlayerRequests);
  };

  // Function to handle cancel action in the modal
  const handleConfirmCancelModal = () => {
    handleUpdateStatus(ProfileRequestConstants.STATUS.CANCELLED, true, "Cancelled Request");
    setShowCancelModal(false);
  };

  // Function to handle confirm action in the modal
  const handleConfirmModal = () => {
    if (modalAction !== ProfileRequestConstants.ACTIONS.RESUBMIT) {
      const newStatus =
        modalAction === ProfileRequestConstants.ACTIONS.SUBMIT_TO_BOC
          ? ProfileRequestConstants.STATUS.SUBMITTED_TO_BOC
          : modalAction === ProfileRequestConstants.ACTIONS.SUBMIT_TO_CLUB
          ? ProfileRequestConstants.STATUS.SUBMITTED_TO_CLUB
          : modalAction === ProfileRequestConstants.ACTIONS.APPROVE
          ? ProfileRequestConstants.STATUS.APPROVED
          : modalAction === ProfileRequestConstants.ACTIONS.DENY
          ? ProfileRequestConstants.STATUS.DENIED
          : null;
      handleUpdateStatus(newStatus, true, "Submitted");
    } else {
      handleResubmit(true, "Submitted");
    }
    setShowConfirmModal(false);
  };

  const requestRequired = isStatusRequestRequired(row?.requestStatus);
  const submittedToBOC = isStatusSubmittedToBOC(row?.requestStatus);
  const submittedToClub = isStatusSubmittedToClub(row?.requestStatus);
  const canCancelRequest = submittedToBOC || submittedToClub;
  const canResubmitRequest = isBOCadmin && isStatusCancelled(row?.requestStatus);

  const rowHasActions =
    ((requestRequired || submittedToBOC || submittedToClub) && !(isFedUser || isBOCviewer || isTeamCoordinator)) ||
    canResubmitRequest;

  useEffect(() => {
    if (showConfirmModal) {
      const modalDetails = [
        ProfileRequestConstants.ACTIONS.SUBMIT_TO_BOC,
        ProfileRequestConstants.ACTIONS.SUBMIT_TO_CLUB
      ].includes(modalAction)
        ? ProfileRequestConstants.MODAL_TEXT.SUBMIT
        : modalAction === ProfileRequestConstants.ACTIONS.APPROVE
        ? ProfileRequestConstants.MODAL_TEXT.APPROVE
        : modalAction === ProfileRequestConstants.ACTIONS.DENY
        ? ProfileRequestConstants.MODAL_TEXT.DENY
        : modalAction === ProfileRequestConstants.ACTIONS.RESUBMIT
        ? ProfileRequestConstants.MODAL_TEXT.RESUBMIT
        : null;
      dispatch({
        type: "openConfirmSubmitModal",
        headerText: modalDetails.headerText,
        content: modalDetails.content,
        updateStatusButtonText: modalAction,
        onConfirmRequestFn: handleConfirmModal,
        profileId: row?.profileId,
        submitProfile: row
      });
      setShowConfirmModal(false);
    }
  }, [showConfirmModal]);

  useEffect(() => {
    if (showCancelModal) {
      dispatch({
        type: "openConfirmCancelModal",
        updateStatusButtonText: "Cancel Request",
        onConfirmUpdateFn: handleConfirmCancelModal,
        profileId: row?.profileId
      });
      setShowCancelModal(false);
    }
  }, [showCancelModal]);

  return rowHasActions ? (
    <>
      <MenuButton ref={ref} onClick={() => setIsOpen(!isOpen)}>
        <MenuIcon iconName="fa-ellipsis-v" />
      </MenuButton>
      <ContextMenu targetRef={ref} isOpen={isOpen} toggleMenu={toggleMenu}>
        {requestRequired && <MenuItem onClick={handleSubmitToBOC}>Submit to BOC</MenuItem>}
        {submittedToBOC && <MenuItem onClick={handleSubmitToClub}>Submit to Club</MenuItem>}
        {submittedToClub && (
          <MenuItem onClick={() => handleClubDecision(ProfileRequestConstants.ACTIONS.APPROVE)}>Approve</MenuItem>
        )}
        {submittedToClub && (
          <MenuItem onClick={() => handleClubDecision(ProfileRequestConstants.ACTIONS.DENY)}>Deny</MenuItem>
        )}
        {canCancelRequest && <MenuItem onClick={handleCancelRequest}>Cancel Request</MenuItem>}
        {canResubmitRequest && <MenuItem onClick={handleResubmitRequest}>Resubmit to BOC</MenuItem>}
      </ContextMenu>
    </>
  ) : (
    <>
      <MenuButton ref={ref} onClick={() => setIsOpen(!isOpen)}>
        <InactiveMenuIcon iconName="fa-ellipsis-v" disabled={true} />
      </MenuButton>
    </>
  );
};

RequestActionMenuFormatter.propTypes = {
  row: PropTypes.object
};

export default RequestActionMenuFormatter;
