import React, { useContext, useCallback } from "react";
import styled from "styled-components";
import WBCCollapse from "../elements/WBCCollapse";
import RosterContext from "../../contexts/RosterContext";
import WBCYearsContext from "../../contexts/WBCYearsContext";
import Infield from "./final/Infield";
import Outfield from "./final/Outfield";
import Pitchers from "./final/Pitchers";
import Catchers from "./final/Catchers";
import WBCPrimaryOutlinedButton from "../elements/WBCPrimaryOutlinedButton";
import AuthContext from "../../contexts/AuthContext";
import SelectedTeamContext from "../../contexts/SelectedTeamContext";
import GlobalModalContext from "../../contexts/GlobalModalContext";
import FinalizedStatusContext from "../../contexts/FinalizedStatusContext";
import TournamentTypeContext from "../../contexts/TournamentTypeContext";
import ExportApi from "../../httpClients/ExportApi";
import FileHelper from "../../httpClients/FileHelper";

const Container = styled.div`
  margin-bottom: 18px;
`;

const Text = styled.div`
  font-family: Helvetica;
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: ${props => props.theme["dark-grey"]};
`;

const RosterContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FinalizeWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-top: 16px;
`;

const FinalizeButton = styled(WBCPrimaryOutlinedButton)`
  &&&& {
    width: 118px;
  }
`;

const FinalRoster = () => {
  // context(s)
  const authContext = useContext(AuthContext);
  const rosterContext = useContext(RosterContext);
  const { selectedWbcYear, selectedYear } = useContext(WBCYearsContext);
  const selectedTeamContext = useContext(SelectedTeamContext);
  const globalModalContext = useContext(GlobalModalContext);
  const finalizedStatusContext = useContext(FinalizedStatusContext);
  const tournamentType = useContext(TournamentTypeContext);

  // variable(s)
  const { dispatch: rosterDispatch } = rosterContext;
  const { final, finalIsOpen } = rosterContext.state;
  const { isBOCadmin, isFedAdmin, isTeamCoordinator } = authContext.state;
  const { team } = selectedTeamContext.state;
  const { finalized } = finalizedStatusContext.state;
  const size = Object.values(final).reduce((pv, c) => pv + c.length, 0);
  const settings = (selectedWbcYear && selectedWbcYear[`${tournamentType}TournamentSettings`]) || null;

  const canEdit = isBOCadmin || isFedAdmin || isTeamCoordinator;

  // function(s)
  const finalizeRoster = () => {
    globalModalContext.dispatch({
      type: "openFinalizeRosterModal",
      content: { team, prefix: tournamentType }
    });
  };

  const exportFn = useCallback(() => {
    ExportApi.exportFinalRoster(selectedYear, team.teamId, tournamentType, (url, headers) => ({ url, headers })).then(
      data => {
        const test = new Blob([data.url], { type: data.headers["content-type"] });
        const url = window.URL.createObjectURL(test);
        FileHelper.downloadFile(url, data.headers);
      }
    );
  }, [selectedYear, team.teamId, tournamentType]);

  const clickedFinalCollapse = isOpen => {
    rosterDispatch({
      type: "setFinalIsOpen",
      isOpen
    });
  };

  return (
    settings && (
      <Container>
        <WBCCollapse
          exportable
          title={`Final (${size})`}
          subTitle={`${settings.rosterNumbers.finalRosterMax} player max`}
          startsOpen={finalIsOpen}
          exportFn={exportFn}
          openCloseCallback={clickedFinalCollapse}
        >
          {canEdit && <Text>Drag players into position slots from the provisional roster</Text>}
          <RosterContainer>
            <Catchers settings={settings.positions} />
            <Pitchers settings={settings.positions} />
            <Infield settings={settings.positions} />
            <Outfield settings={settings.positions} />
          </RosterContainer>
        </WBCCollapse>
        {(isBOCadmin || isFedAdmin) && !finalized ? (
          <FinalizeWrapper>
            <FinalizeButton
              onClick={event => {
                event.stopPropagation();
                finalizeRoster();
              }}
            >
              Finalize Roster
            </FinalizeButton>
          </FinalizeWrapper>
        ) : null}
      </Container>
    )
  );
};

export default FinalRoster;
