import BaseApi from "./BaseApi";

export default class WBCTeamApi extends BaseApi {
  static getFederationTeamsByYear = year => this.get(`federationTeams/year/${year}`);

  static getFederationTeamByTeamId = teamId => this.get(`federationTeams/${teamId}`);

  static getCountries = ({ withAssociatedTeams } = { withAssociatedTeams: false }) =>
    this.get(`countries?withAssociatedTeams=${withAssociatedTeams}`);

  static saveTeams = teams => this.post("federationTeams/bulk", teams);

  static getFedTeamsMatchingProfile = profileId => this.get(`federationTeams/profile/${profileId}`);

  static finalizeRoster = (teamId, tournamentType, override = false) =>
    this.patch(`federationTeams/${teamId}/finalizeRoster/${tournamentType}?override=${override}`);

  static finalizeInterestList = teamId => this.patch(`federationTeams/${teamId}/finalizeInterestList`);
}
